const getColor = (status) => {
  switch (status){
    case 'created':
      return 'primary'
    case 'assigned':
      return 'green'
    case 'closed':
      return 'red'
  }
}

export {
  getColor
}