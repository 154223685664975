var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("fa-arrow-left")])],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-toolbar-title',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$t("admin.my_tickets")))]):(!_vm.searchingMode)?_c('v-toolbar-title',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$t("admin.my_tickets")))]):_vm._e(),_c('v-spacer'),_c('searchfield',{on:{"searching":function($event){_vm.searchingMode = $event},"searchinfo":_vm.searchTicket}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lists,"page":_vm.page,"items-per-page":_vm.perPage,"fixed-header":"","disable-sort":"","footer-props":{
        'items-per-page-options':[5,10,15,20]
      },"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t(("ticket.status_type." + (item.status))))+" ")])]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY HH:mm:ss")))])]}},{key:"item.options",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"info"},on:{"click":function($event){return _vm.goChat(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-comment")])],1)]}}],null,true)},[_c('span',[_vm._v("Chat")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }